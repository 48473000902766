//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-608:_4056,_3062,_5672,_8428,_6848,_7888,_1534,_4672;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-608')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-608', "_4056,_3062,_5672,_8428,_6848,_7888,_1534,_4672");
        }
      }catch (ex) {
        console.error(ex);
      }